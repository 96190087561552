import './style.scss'
import { Carousel, Drawer } from 'antd';
import { useState } from 'react';
import { ArrowLeftOutlined, BarsOutlined } from '@ant-design/icons';
import FooterCom from '../../../components/FooterCom';
import TeacherCom from '../HkEngListPage/teacherCom'
const HkMathListPage = () => {
    const [open, setOpen] = useState(false);
    const [num, setNum] = useState(0);
    const teacherList = [{ img: "../assets/HkMathImg/t1.png", name: "曾沅晴", text: "", info: "擁有豐富的知識和熱情，來激發學生對數學的興趣。教學風格非常親切和耐心，能夠以清晰的方式傳授數學概念，並促進學生數學思維和問題解決技能的發展。" },
    { img: "../assets/HkMathImg/t2.jpg", name: "何文龙", text: "", info: "擁有超過五年廣東省線下教育培訓機構工作經驗，以及超過三年學校小學數學教學經驗。用心教育、耐心傳授、注重細節、靈活運用。" },
    { img: "../assets/HkMathImg/t3.jpg", name: "王婉如", text: "", info: "小學二至六年級數學，能幫助學生建立嚴謹的思維邏輯體系。教師年輕有活力，課堂氛圍良好。" }]

    const showDrawer = () => {
        setOpen(!open);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleClick = (index) => {
        console.log(index);
        setNum(index)
        let name = ''
        switch (index) {
            case 0:
                name = '.page'
                break;
            case 1:
                name = '.page1'
                break;
            case 2:
                name = '.page3'
                break;
            case 3:
                name = '.page5'
                break;
            case 4:
                name = '.page6'
                break;
        };
        scrollToElement(name, {
            offset: -80, // 额外偏移量
            smooth: true
        });
    }
    const scrollToElement = (element, options = {}) => {
        const el = typeof element === 'string'
            ? document.querySelector(element)
            : element;
        if (!el) {
            console.error('元素不存在:', element);
            return;
        }
        const headerOffset = document.querySelector('.fixed-header')?.offsetHeight || 0;
        const targetPosition = el.getBoundingClientRect().top + window.pageYOffset;
        const offset = options.offset || 0;
        const scrollToY = targetPosition - headerOffset + offset;
        console.log('scrollToY', scrollToY);
        window.scrollTo({
            top: scrollToY,
            behavior: options.smooth ? 'smooth' : 'auto'
        });
    }
    const buttonCom = (data) => {
        return <div className='page_button'>
            <p className='button'>
                {data ? data : '點擊咨詢'}
            </p>
        </div>
    }
    return (
        <div className="HkMathListPage">
            <div className='head_title'>
                <div className='head_icon'>
                    {open == true ? <ArrowLeftOutlined onClick={() => { showDrawer() }} /> : <BarsOutlined onClick={() => { showDrawer() }} />}
                </div>
                <img src="../assets/logo.png" alt="" />
                <div className='head_item'>
                    {
                        ['Home', 'About', '數學課程', '教師團隊', 'FAQ'].map((item, index) => {
                            return (
                                <span key={index} className={`${index === num ? 'active' : ''}`} onClick={() => { handleClick(index) }}><p className={`${index === num ? 'active' : ''}`}>{item}</p></span>
                            )
                        })
                    }
                </div>
            </div>
            <Drawer onClose={onClose} open={open} className="drawer" width={200}>
                {
                    ['Home', 'About', '數學課程', '教師團隊', 'FAQ'].map((item, index) => {
                        return (
                            <p key={item} className={['drawer_item', index === num ? 'active_p' : ''].join(' ')} onClick={() => { handleClick(index) }}>{item}</p>
                        )
                    })
                }
            </Drawer>
            <div className='page'>
                <div className='swperItem'>
                    <img src='../assets/HkMathImg/banner1.jpg' alt="" />
                    <div className='page_text'>
                        <h2>Online 1V1 Mathematics Tuition</h2>
                        <p className='title'>1對1數學補習網課</p>
                        <p className='button'>
                            點擊咨詢
                        </p>
                    </div>
                </div>
            </div>
            <section className="page1 section context-dark">
                <div className="page1">
                    <div className="page1_item teal">
                        <img src="../assets/HkMathImg/icon2.png" alt="" />
                        <h6 className="title">97%</h6>
                        <p className="exeption">提分率</p>
                    </div>
                    <div className="page1_item cyan">
                        <img src="../assets/HkMathImg/icon1.png" alt="" />
                        <h6 className="title">5K+</h6>
                        <p className="exeption">在讀學生</p>
                    </div>
                    <div className="page1_item orange">
                        <img src="../assets/HkMathImg/icon4.png" alt="" />
                        <h6 className="title">5K+</h6>
                        <p className="exeption">好評</p>
                    </div>
                    <div className="page1_item purple">
                        <h6 className="title">98%</h6>
                        <p className="exeption">家長滿意度</p>
                        <a className="button-280 button-default-outline" >Learn More</a>
                    </div>
                </div>
            </section>

            <div className='page2'>
                <div className='container'>
                    <p>在Sino-bus補習數學，我們致力於為您的孩子打造全方位的學習體驗，不僅著重於數學成績的提升，更全面培養其卓越的思維能力、精準的分析能力以及高效的總結能力。透過我們精心設計的課程，您的孩子將掌握實用的數學技巧，並在專業教師的引導下，逐步建立對數學的自信與熱情。我們深信，透過系統化的學習與個別化的指導，您的孩子將在數學領域取得顯著的進步，為未來的學業與生涯奠定堅實的基礎。</p>
                </div>
                <div className='offer_box integrateWith'>
                    <div className='item'>
                        <img src="../assets/HkMathImg/tu7.png" alt="" />
                        <h6>高效提分</h6>
                        <p className='exeption'>· 我們深知每一分都至關重要，因此課程設計緊密圍繞香港考試重點，精準把握提分關鍵。
                            <br />· 透過系統化的學習方法和針對性的練習，幫助學生在最短時間內實現顯著的成績提升。
                            <br />· 我們致力於幫助學生在學業上取得突破，讓每一分努力都轉化為實際的進步。</p>
                    </div>
                    <div className='item'>
                        <img src="../assets/HkMathImg/tu5.png" alt="" />
                        <h6>1對1課程</h6>
                        <p className='exeption'>· 量身定制的學習計畫，確保每一位學生都能得到最適合自己的教學內容和進度。<br />
                            · 專注於學生個體差異，提供精準指導，有效解決學習中的難點和疑惑。<br />
                            · 在1對1的學習環境中，學生可以更自由地提問和互動，充分發揮學習潛能。</p>
                    </div>
                    <div className='item'>
                        <img src="../assets/HkMathImg/tu6.png" alt="" />
                        <h6>經驗教師</h6>
                        <p className='exeption'>· 我們的教師團隊擁有豐富的教學經驗，深諳考試規律和學生學習特點。<br />
                            · 他們不僅是知識的傳授者，更是學生的引導者，激發學習興趣，培養學習能力。<br />
                            · 教師們以專業的教學素養和高度的責任心，為學生的學業成功保駕護航。</p>
                    </div>
                    <div className='item'>
                        <img src="../assets/HkMathImg/tu2.png" alt="" />
                        <h6>生動教學</h6>
                        <p className='exeption'>· 告別枯燥乏味的傳統教學，我們的課程充滿趣味性和互動性，讓學習變得輕鬆愉快。<br />
                            · 運用多樣化的教學方法和生動的案例分析，幫助學生深入理解知識點，提升學習效果。<br />
                            · 我們致力於創造一個充滿活力的學習環境，讓學生在輕鬆愉快的氛圍中愛上學習。</p>
                    </div>
                    <div className='item'>
                        <img src="../assets/HkMathImg/tu1.png" alt="" />
                        <h6>互動課堂</h6>
                        <p className='exeption'>· 鼓勵學生積極參與課堂討論和互動，培養獨立思考和解決問題的能力。<br />
                            · 透過豐富的課堂活動和團隊合作，激發學生的學習熱情和創造力。<br />
                            · 我們相信，互動式學習能夠讓學生更深入地理解和掌握知識，提升學習效果。</p>
                    </div>
                    <div className='item'>
                        <img src="../assets/HkMathImg/tu3.png" alt="" />
                        <h6>定製化提升</h6>
                        <p className='exeption'>· 我們深入了解每一位學生的學習情況和需求，制定個性化的學習方案。<br />
                            · 針對學生的薄弱環節，進行重點突破，幫助學生全面提升學業水平。<br />
                            · 我們致力於幫助學生實現全面的學業提升，讓他們在學習中找到自信和成就感。</p>
                    </div>
                </div>
            </div>
            {buttonCom()}
            <div className='page3'>
                <h2 className="heading">
                    數 學 課 程
                </h2>
                <div className='programs'>
                    <div className='programs_item'>
                        <img src="../assets/HkMathImg/child1.png" alt="" />
                        <h6>教學課件</h6>
                        <p>涵蓋港澳主流教材（小學數學新思維，現代小學數學，名創小學數學，小學數學與生活等），緊扣教育局大綱，生動動畫輔助，教研團隊匠心打造，助孩子輕鬆掌握數學精髓</p>
                    </div>
                    <div className='programs_item'>
                        <img src="../assets/HkMathImg/child2.png" alt="" />
                        <h6>課程特色</h6>
                        <p>以學生為中心，量身定制學習方案。結合思維拓展、試題分析及數形結合，引導孩子深度沉浸於數學世界。</p>
                    </div>
                    <div className='programs_item'>
                        <img src="../assets/HkMathImg/child3.png" alt="" />
                        <h6>教學目的</h6>
                        <p>精準提升數感運算、數理技巧，強化解題能力。針對孩子弱點，因材施教，助力數學考試成績顯著提升。</p>
                    </div>
                </div>
            </div>
            <div className='page4 integrateWith'>
                <h2 className="heading">
                    好評<span></span>
                </h2>
                <div className='teacher_box teacher_box_pc'>
                    {
                        [1, 2, 3]?.map((item, index) => {
                            return <div className='swperItem' key={index}>
                                <img src={'../assets/HkMathImg/hao' + (index + 1) + ".jpg"} alt="" />
                            </div>
                        })
                    }
                </div>
                <Carousel autoplay className=' teacher_box teacher_box_mob'>
                    {
                        [1, 2, 3]?.map((item, index) => {
                            return <div className='swperItem' key={index}>
                                <img src={'../assets/HkMathImg/hao' + (index + 1) + ".jpg"} alt="" />
                            </div>
                        })
                    }
                </Carousel>
            </div>
            <div className='page5'>
                <h2 className="heading">
                    教師團隊
                </h2>
                <TeacherCom teacherList={teacherList} />
            </div>
            <div className='page6'>
                <h2 className="heading">
                    常 見 問 題
                </h2>
                <div className='questions_box'>
                    <div className='questions_item'>
                        <h6>數學課程主要是針對幾年級的小朋友？</h6>
                        <p>Sino-bus的數學課程主要包括K2-P6年級的小朋友，課程會按照實際測試小朋友的數學水平進行建議和授課，一切以解決小朋友的數學難點、克服弱點，最終提高數學能力和數學成績為目標</p>
                    </div>
                    <div className='questions_item'>
                        <h6>數學課程主要是針對幾年級的小朋友？</h6>
                        <p>Sino-bus的數學課程主要包括K2-P6年級的小朋友，課程會按照實際測試小朋友的數學水平進行建議和授課，一切以解決小朋友的數學難點、克服弱點，最終提高數學能力和數學成績為目標</p>
                    </div>
                    <div className='questions_item'>
                        <h6>數學課程主要是針對幾年級的小朋友？</h6>
                        <p>Sino-bus的數學課程主要包括K2-P6年級的小朋友，課程會按照實際測試小朋友的數學水平進行建議和授課，一切以解決小朋友的數學難點、克服弱點，最終提高數學能力和數學成績為目標</p>
                    </div>
                    <div className='questions_item'>
                        <h6>數學課程主要是針對幾年級的小朋友？</h6>
                        <p>Sino-bus的數學課程主要包括K2-P6年級的小朋友，課程會按照實際測試小朋友的數學水平進行建議和授課，一切以解決小朋友的數學難點、克服弱點，最終提高數學能力和數學成績為目標</p>
                    </div>
                    <div className='questions_item'>
                        <h6>數學課程主要是針對幾年級的小朋友？</h6>
                        <p>Sino-bus的數學課程主要包括K2-P6年級的小朋友，課程會按照實際測試小朋友的數學水平進行建議和授課，一切以解決小朋友的數學難點、克服弱點，最終提高數學能力和數學成績為目標</p>
                    </div>
                </div>
            </div>
            {buttonCom('Contact Us')}
            <FooterCom />
        </div >
    );
};
export default HkMathListPage;