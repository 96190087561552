import './teacherStyle.scss'
import { Carousel } from 'antd';
const TeacherCom = ({ teacherList }) => {
    return (
        <div className='teacherCom'>
            <div className='teacher_box teacher_box_pc'>
                {teacherList?.map((item, index) => {
                    return <div className='swperItem' key={index}>
                        <img src={item.img} alt="" />
                        <h3>{item.name}</h3>
                        <h6>{item.text}</h6>
                        <h6>{item.info}</h6>
                    </div>
                })}
            </div>
            <Carousel autoplay className='teacher_box teacher_box_mob'>
                {
                    teacherList?.map((item, index) => {
                        return <div className='swperItem' key={index}>
                            <img src={item.img} alt="" />
                            <h3>{item.name}</h3>
                            <h6>{item.text}</h6>
                            <h6>{item.info}</h6>
                        </div>
                    })
                }
            </Carousel>
        </div>

    )
}
export default TeacherCom