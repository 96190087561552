import './style.scss'
import { Carousel } from 'antd';
import FooterCom from '../../../components/FooterCom';
import { MessageOutlined, ReadOutlined, UsergroupAddOutlined, SmileOutlined } from '@ant-design/icons';
import TeacherCom from './teacherCom'
const HkEngListPage = () => {
    const teacherList = [{ img: "../assets/HkEngListImg/t1.png", name: "Eric老師", text: "河北大學碩士研究生", info: "小升初英文深受孩子歡迎；小學生英文自然拼讀；輔導過考研英語閱讀；教學風格、理念- 生動、活潑、幽默有趣" },
    { img: "../assets/HkEngListImg/t2.png", name: "Hannah老師", text: "香港理工大學碩士研究生", info: "國家教師資格證書，普通話一級乙等，英語專業四級，英語六級，雅思導師" },
    { img: "../assets/HkEngListImg/t3.png", name: "Sunny老師", text: "廣東外語外貿大學碩士研究生", info: "21年大學英語授課經驗，精通聽說、閱讀、寫作各方面；15年小初高英語輔導經驗，擅長學科提分；兼職教授廣東及港澳地區小朋友普通話，家長反映良好。" }]
    const classList = [
        { img: "../assets/HkEngListImg/icon1.png", title: "教學課件", content: "全面覆蓋港澳主流英文教材，嚴格參考香港教育局英文教育大綱，輔以生動有趣的動畫，由經驗豐富的教研團隊獨立研發完成。" },
        { img: "../assets/HkEngListImg/icon2.png", title: "課程特色", content: "以學生爲中心，定制化學習，通過任務闖關模式，情景化教學，角色扮演等遊戲化學習，讓孩子沉浸其中。" },
        { img: "../assets/HkEngListImg/icon3.png", title: "教學理念", content: "費曼教學法”，以學生為主體，寓教於樂，1對1實時互動真人教學，及時答疑解惑。" },
        { img: "../assets/HkEngListImg/icon4.png", title: "教學目的", content: "全面鍛煉孩子的聼、說、讀、寫能力，提高英語綜合素養，培養英語思維。同時針對小朋友具體缺失點，因材施教，從而提升英文考試成績。" }
    ]
    return (
        <div className="HkEngListPage">
            <section className="page1 integrateWith" id="home">
                <div className="row d-flex align-items-center flex-wrap gap-4">

                    <div className="content">
                        <h1>
                            Online 1V1 English Tuition
                            <br />
                            <span>1對1英文補習網課</span>
                        </h1>
                        <a href="#contact" className="btn">
                            點擊咨詢
                        </a>
                    </div>

                    <div className="image w-100">
                        <img src="../assets/HkEngListImg/book.png" alt="..." />
                    </div>

                </div>
            </section>
            <div className='page2 integrateWith'>
                <div className="box">

                    <i className="bi bi-book"><ReadOutlined /></i>
                    <div className="content">
                        <h3>97%</h3>
                        <p>提分率</p>
                    </div>
                </div>
                <div className="box">
                    <i className="bi bi-book"><UsergroupAddOutlined /></i>
                    <div className="content">
                        <h3>5K+ </h3>
                        <p>在讀學生</p>
                    </div>
                </div>
                <div className="box">
                    <i className="bi bi-book"> <MessageOutlined /></i>
                    <div className="content">
                        <h3>5K+</h3>
                        <p>好評</p>
                    </div>
                </div>
                <div className="box">
                    <i className="bi bi-book"><SmileOutlined /></i>
                    <div className="content">
                        <h3>98%</h3>
                        <p>家長滿意度</p>
                    </div>
                </div>
            </div>
            <section className="why integrateWith" id="why">
                <div className="row d-flex align-items-center flex-wrap gap-4 page3">
                    <div className="image">
                        <img className="w-100" src="../assets/HkEngListImg/question.png" alt="..." />
                    </div>
                    <div className="content text-center">
                        <h2 className="pt-5 pt-md-0">
                            Why Choose Us?
                        </h2>
                        <p>
                            高效的提分效果，精準的協助方向，靈活的上課時間，有趣的教學模式
                            選擇Sino-bus 英文1對1補習網課，現在就聯系！
                        </p>
                        <a href="#contact" className="btn">
                            免費試堂
                        </a>
                    </div>

                </div>
            </section>
            <div className="page4 integrateWith">
                <h2 class="heading">
                    课程 <span>介绍</span>
                </h2>
                <p className='title'>基於CEFR研發的適合香港孩子的英語進階體系。</p>
                <p>  該體系為Sino-bus基於CEFR（歐洲語言共同參考框架）為香港青少學員專業研發的英語級別體系，接軌劍橋英語YLE及聖三一英語口語GESE，PET,KET,FCE,TOEFL等國際標準化考試，為每位香港青少學員的英語學習提供 清晰可衡量的成長進步。</p>
                <img className='classImg_pc' src="../assets/HkEngListImg/class.png" alt="" />
                <img className='classImg_mob' src="../assets/HkEngListImg/class_mob.png" alt="" />
                <div className='calssBox'>
                    <img src="../assets/HkEngListImg/class1.png" alt="" />
                    <img src="../assets/HkEngListImg/class2.png" alt="" />
                    <img src="../assets/HkEngListImg/class3.png" alt="" />
                </div>
                <div className='courses_box courses_box_pc'>
                    {
                        classList?.map((item, index) => {
                            return <div className='courses_box_item'>
                                <img src={item.img} alt="" />
                                <h3 className='title'>{item.title}</h3>
                                <p className='content'>{item.content}</p>
                            </div>
                        })
                    }
                </div>
                <Carousel autoplay autoplaySpeed={3500} className='courses_box courses_box_mob'>
                    {
                        classList?.map((item, index) => {
                            return <div className='courses_box_item'>
                                <img src={item.img} alt="" />
                                <h3 className='title'>{item.title}</h3>
                                <p className='content'>{item.content}</p>
                            </div>
                        })
                    }

                </Carousel>
            </div>
            <div className='page5 integrateWith'>
                <h2 class="heading">
                    老师 <span>簡介</span>
                </h2>
                <TeacherCom teacherList={teacherList} />
            </div>
            <div className='page_btn'>
                <a href="#contact" className="btn">
                    點擊咨詢
                </a>
            </div>
            <div className='page5 integrateWith'>
                <h2 class="heading">
                    好評<span></span>
                </h2>
                <div className='teacher_box teacher_box_pc'>
                    {
                        [1, 2, 3]?.map((item, index) => {
                            return <div className='swperItem'>
                                <img src={'../assets/HkEngListImg/experts' + (index + 1) + ".png"} alt="" />
                            </div>
                        })
                    }
                </div>
                <Carousel autoplay className=' teacher_box teacher_box_mob' autoplaySpeed={4000}>
                    {
                        [1, 2, 3]?.map((item, index) => {
                            return <div className='swperItem'>
                                <img src={'../assets/HkEngListImg/experts' + (index + 1) + ".png"} alt="" />
                            </div>
                        })
                    }
                </Carousel>
            </div>
            <FooterCom />
        </div>
    );
};
export default HkEngListPage;