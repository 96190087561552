import Home from "../page/Home"
import HomePage from "../page/HomePage";
import HongKongMathsPage from "../page/HongKongMathsPage";
import HongKongPage from "../page/HongKongPage"
import SingaporePage from "../page/SingaporePage";
import AboutUsPage from "../page/AboutUsPage";
import BlogInfo from "../page/BlogInfo";
import HkClass from "../page/AdvertisingPage/Hk-class";
import HkCom from "../page/AdvertisingPage/Hk";
import Singapore from "../page/AdvertisingPage/Singapore1";
import HkOnlinePage from '../page/AdvertisingPage/HkOnlinePage'
import HkOnlinePage2 from "../page/AdvertisingPage/HkOnlinePage2";
import HkPage from "../page/AdvertisingPage/HkPage";
import YouTube from "../page/AdvertisingPage/YouTube";
import HkClassPuls from "../page/AdvertisingPage/HkClassPuls";
import TransitPage from "../page/AdvertisingPage/Hk-class/transitPage.jsx";
import Privacy from "../page/Privacy";
import HongKongEngPage from "../page/HongKongEngPage";
import HkEngListPage from "../page/AdvertisingPage/HkEngListPage";
import HkMathListPage from "../page/AdvertisingPage/HkMathListPage";
const routes = [
    {
        exact: true,
        path: "/:id",
        element: <BlogInfo />,
    },
    {
        exact: true,
        path: "/",
        element: <Home />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/hongkongmaths",
                element: <HongKongMathsPage />,
            },
            {
                path: "/hongkong",
                element: <HongKongPage />,
            },
            {
                path: "/hongkong-English",
                element: <HongKongEngPage />,
            },
            {
                path: "/singapore",
                element: <SingaporePage />,
            },
            {
                path: "/aboutus",
                element: <AboutUsPage />,
            },

        ]
    },
    {
        exact: true,
        path: "/hk-zh/",
        element: <HkClassPuls />
    },
    {
        exact: true,
        path: "/hkonlinetutoring/",
        element: <HkClass />
    },
    {
        exact: true,
        path: "/hkonlinetutoring1/",
        element: <TransitPage />
    },
    {
        exact: true,
        path: "/hkch/",
        element: <HkCom />
    },
    {
        exact: true,
        path: "/singapore1/",
        element: <Singapore />
    },
    {
        exact: true,
        path: "/hkonlinechinesetutoring/",
        element: <HkOnlinePage />
    },
    {
        exact: true,
        path: "/googlehkonlineclass/",
        element: <HkOnlinePage2 />
    },
    {
        exact: true,
        path: "/hk/",
        element: <HkPage />
    },
    {
        exact: true,
        path: "/Sino-busYouTube",
        element: <YouTube />
    },
    {
        exact: true,
        path: "/privacy",
        element: <Privacy />
    },
    {
        exact: true,
        path: "/hk-english-list",
        element: <HkEngListPage />
    },
    {
        exact: true,
        path: "/hk-math-list",
        element: <HkMathListPage />
    }
]
export default routes;