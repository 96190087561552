export const HomePageData = {
    pageList: [
        { imgUrl: "../assets/hongkongLogo.png", countryName: "香港", classTitle: "课程简介", classInfo: "小学全面提高班课程结合香港小学学习字词表，整合《活学中国语文》《我爱学语文》《现代中国语文》等编写而成，紧跟学校课程，拓展教材内容。中学同步提高班参考《启思中国语文》等教材独立研发提升学生能力课件，通过一对一的私人订制课程，重视认字组句、阅读理解、习作表达等方面，最终全面提升学生的语文能力。" },
        { imgUrl: "../assets/singaporeLogo.png", countryName: "新加坡", classTitle: "课程简介", classInfo: "《欢乐伙伴》以新加坡教育部颁发的《欢乐伙伴》系列教材为教学大纲，同步推出专为新加坡孩子设计的能力提升课程……" },
        { imgUrl: "../assets/otherLogo.png", countryName: "其他华裔地区", classTitle: "课程简介", classInfo: "面向4—16岁海外华裔少儿先进的在线教育平台沉浸式1对1教学/ 优质小班故事会 / 1对多中文趣味课平台影响力遍布全球多个国家和地区" }]

}
export const HongKongPageData = {
    courseDataList: [{ url: "../assets/HK/courseLogo1.png", title: "幼小銜接補習課程", content: "課程設有看圖認字、筆順結構、聯想造詞、看圖等內容，安排闖關活動、識字遊戲，趣味性的練習，重視視覺感知、語音意識、語素意識、構字能力、書寫技巧的提升。真正將兒童的興趣、探索與創造融為一體，在帶動兒童興趣及思維的基礎上，體驗學習的快樂，並幫助孩子銜接小一課程。" },
    { url: "../assets/HK/courseLogo2.png", title: "全面提高班課程", content: "小學全面提高班課程結合香港小學學習字詞表，整合《活學中國語文》《我愛學語文》《現代中國語文》等編撰而成，緊跟學校課程，拓展教材內容。中學同步提高班參考《啟思中國語文》等教材獨立研發提升學生能力課件，通過1對1的私人訂製課程，重視認字組句、閱讀理解、習作表達等方面，最終全面提升學生的語文能力。" },
    { url: "../assets/HK/courseLogo3.png", title: "呈分試補習課", content: "課程包括聆聽、說話、閱讀習作三部分，注重中文綜合能力的提高。舉一反三，教授答題技巧，對錯題進行歸納分析，減少錯別字等失誤失分。熟悉不同文體的寫作技巧，掌握成語、修辭手法的運用，積累好句。對詞語、句子、語段進行分析，提升閱讀理解能力，學會審題，了解文章重要主旨。通過聆聽音頻，集中注意力，學會抓住重點。" },
    { url: "../assets/HK/courseLogo4.png", title: "閱讀理解與寫作專項提高班", content: "小學閱讀課通過劃分句段、整合框架等方法了解文章內容主旨，提升閱讀敏感度，掌握答題技巧。小學寫作課主要是熟悉日記、記敍文、描寫文，說明文等文體，學習總分總框架組織等方法，整體提升習作能力。中學寫作專項課包括记敍、抒情和議論等更多文體的寫作技巧和知識。通過以讀帶寫，因材施教，課程內容多元化，激發學生寫作興趣，從審題、立意、構思、選材等方面入手，解決學生寫作難點，提升寫作能力。" },
    { url: "../assets/HK/courseLogo5.png", title: "文言文專項輔導課", content: "根據中國語文課程指引為設計依據，以香港教育部發佈的中學建議篇章篇目及補充內容為教學重點，文言文專項課程包括文言實詞、虛詞，古今異義，特殊文言句式等多個考點，緊密貼合教材，多元拓展，積累文言文應試能力的同時提升中國文化知識，豐富文學素養，促進學生思辨能力發展。" },
    { url: "../assets/HK/courseLogo6.png", title: "白話文專項輔導課", content: "白話文閱讀專項課程，品讀經典名家作品，學習多種閱讀策略，總結題型過程中幫助學生掌握白話文閱讀答題技巧，程難點螺旋上升，易於學生理解學習，滲入DSE題型，預早熟習公開試題。" },
    ],
    teacherDatalist: [{ color: "#C4EFDC", teacherHead: "../assets/HK/teacher1.png", teacherName: "王君慧老師", titleName: "跨文化交流碩士", teacherInfo: "悉尼大學畢業，持有普通話二甲證書，掌握普通話、粵語、日語、英語四種語言，漢語言文學學士學位，跨文化交流碩士學位，教學理念：致力讓學習中文成為一件快樂的事情。" },
    { color: "#F9F3FF", teacherHead: "../assets/HK/teacher2.png", teacherName: "王晶晶老師", titleName: "國際漢語注冊老師", teacherInfo: "擁有10年教學工作經驗，擁有國內培訓老師、線上中文教師及線下英文老師等工作經驗。講課生動有趣，能夠根據學生的行為，語言方式，心里特點等進行因材施教，擅於抓住學生注意力，所教授學生范圍3歲至成人，尤其對3至12歲學生的教學更為突出。學生及家長好評率達99%。" },
    { color: "#E8EBCD", teacherHead: "../assets/HK/teacher3.png", teacherName: "楊燕玲老師", titleName: "對外漢語資深老師", teacherInfo: "擁有4年線上對外漢語教學經驗，普通話二甲證書，漢語言專業學士學位，學生累計達1000多名，學生及家長好評率達99%，線上累記授課時長5000小時，教學理念：一棵樹搖動另一棵樹，一朵雲推動另一朵雲，一個靈魂喚醒另一個靈魂。" }
    ],
    serviceContentList: [{ title: "學生檔案升級", info: "升級精美學員檔案，全面記錄學員的學習進程和成長片段。檔案包括課程記錄、測試成績、老師評估等。學員的進步看得見，讓學員為自己的成就感到自豪。", url: "../assets/HK/service1.png" }, { title: "學生檔案升級", info: "", url: "../assets/HK/service2.png" },
    { title: "學生檔案升級", info: "", url: "../assets/HK/service3.png" }, { title: "學生檔案升級", info: "", url: "../assets/HK/service4.png" },
    { title: "學生檔案升級", info: "", url: "../assets/HK/service5.png" }, { title: "學生檔案升級", info: "", url: "../assets/HK/service6.png" },
    { title: "學生檔案升級", info: "", url: "../assets/HK/service7.png" }, { title: "學生檔案升級", info: "", url: "../assets/HK/service8.png" }]
}
export const HongKongMathsPageData = {
    teacherInfoData: [{
        name: "郑老師",
        img: "../assets/maths/pt1.png",
        info: ["對待學生和藹可親，課程基礎知識講解清晰，選的題目比較典型，側重于數學思維邏輯的培養，而非機械操作，知識點講解細緻。", "注重培養學生的綜合能力，注重數學思維的講解，困難的問題解體簡單化。", "知識點由淺入深，特別是一些技巧的講解，擅長基礎方法簡單化。"],
    }, {
        name: "閔老師",
        img: "../assets/maths/pt2.png",
        info: ["長安大學(雙一流)畢業，三年小學數學教學經驗，帶過學生超300人。", "善於將數學知識滲透到多領域科學故事和生活中去，拓展孩子知識面，能抓住孩子注意力。", "課堂風趣生動、有活力。"],
    }, {
        name: "路老師",
        img: "../assets/maths/pt3.png",
        info: ["擁有六年以上教學經驗的教育工作者。", "2017-2021在私立學校擔任初中數學老師一職，2022-2023在一家培訓機構擔任數學老師，主要負責4-8年紀教學工作。", "在六年級升學考試中，95 % 的學生成績都有顯著提高，注重分層教學。"],
    }],
    courseDataList: [{
        grade: "一年級", url: "../assets/Maths/comma1.png", info: "帶領孩子們發現和認識生活中的基本數學問題，通過課堂討論和遊戲活動引領孩子們認識到數學的趣味性和工具性，從而引發孩子們學習數學的興趣和積極性。從基本的數字和空間，時間角度出發，學習掌握基本的數學計算規則和判斷標準，幫助孩子們建立基本的數學素養和數學計算處理數學問題的能力。"
    },
    {
        grade: "二年級", url: "../assets/Maths/comma2.png", info: "通過鞏固基本計算能力的基礎上，引領孩子們掌握從單位數到多位數的識數能力和多種類型計算能力，從難到易逐漸提高學生們的思維水準，幫助學生掌握更多的數學知識從而可以解決生活和學習中會遇到的數學問題，擴張學生們的思維廣度，拔高學生們的思維水準。"
    },
    {
        grade: "三年級", url: "../assets/Maths/comma3.png", info: "帶領學生解決初步進階的乘法、除法、加減乘除混合計算問題，將長度單位、重量單位和體積單位生活化、科技化，生動有趣得講好三角形的故事，帶領孩子的數學完美銜接到難度飛升的4年級。"
    },
    {
        grade: "四年級", url: "../assets/Maths/comma4.png", info: "重訓練、重應用來攻克三位數與兩位數的乘法、除法、加減混合計算及應用題。進一步帶領學生認識分數，解決分數的加減混合運算，同時解決正方形、長方形、圓的周長和面積問題，最後認識數學新朋友小數。"
    },
    {
        grade: "五年級", url: "../assets/Maths/comma5.png", info: "帶領孩子認識大位數，暫且將整數海洋的學習告一段落，繼而轉向對不規則圖形面積、立體圖形空間的探索及初步接觸抽象代數，鍛煉孩子的抽象思維、提升孩子的空間想象力來解決實際的數學問題，除此之外，加強孩子對進階的分數、小數的四則混合運算能力。"
    },
    {
        grade: "六年級", url: "../assets/Maths/comma6.png", info: "六年級我們將實現小學數學計算的完整拼圖：從整數到小數的加減乘除運算及四則混合運算。此後我們將運用這些計算方法並結合方程的概念解決實際生活中遇到的各種數學問題。我們將與孩子繼續深挖平面幾何和立體幾何的各種知識，領略代數和幾何結合的魅力。"
    },
    ],
    advantageData: ["班級組織激發孩子們的學習興趣和動力", "獲得學術上的支持，培養團隊合作和社交能力", "一起學習，分享經驗，互相幫助，建立自信，共同進步", "氛圍活躍，輕鬆愉悅", "與同齡人一起學習和交流，拓展視野，提升自我"],
    dataList: [{ name: "閔老師", url: "../assets/maths/teacher1.png", info: "長安大學(雙一流)畢業，三年小學數學教學經驗，帶過學生超300人。善於將數學知識滲透到多領域科學故事和生活中去，拓展孩子知識面，能抓住孩子注意力。課堂風趣生動、有活力。" },
    { name: "鄭老師", url: "../assets/maths/teacher2.png", info: "  對待學生和藹可親，課程基礎知識講解清晰，選的題目比較典型，側重于數學思維邏輯的培養，而非機械操作，知識點講解細緻。注重培養學生的綜合能力，注重數學思維的講解，困難的問題解體簡單化。知識點由淺入深，特別是一些技巧的講解，擅長基礎方法簡單化。" },
    { name: "路老師", url: "../assets/maths/teacher3.png", info: "擁有六年以上教學經驗的教育工作者。2017-2021在私立學校擔任初中數學老師一職，2022-2023在一家培訓機構擔任數學老師，主要負責4-8年紀教學工作。在六年級升學考試中，95%的學生成績都有顯著提高，注重分層教學。" }
    ],
    dataList1: [{ name: "寧鈞龍老師", url: "../assets/maths/teacher11.png", info: "畢業於中國海洋大學，從事教育行業十年，擁有多年帶高考提分經驗，善於引起學生興趣，深入淺出，所帶學生考取華南農業大學、廣東工業大學等知名學府，深受家長和學生信賴，纍計多帶中小學生3000+。" },
    { name: "李東華老師", url: "../assets/maths/teacher22.png", info: "2018年畢業于暨南大學，掌握普通話、粵語、英語等語言。有四年以上的綫上、綫下教學工作經驗，擅長進行中學、小學的理科科目教學，善於發掘學生在數學理科等方面的學習思路。上課課堂可以做到生動有趣，激發缺省的學習激情，培養學生的學習習慣。教學理念：教育是一件需要努力。需要等待的事情，而學習是人一輩子的重要事。" },
    { name: "李妍鈺老師", url: "../assets/maths/teacher33.png", info: "香港城市大學研究生，曾獲得廣州市教學技能比賽二等獎。 因材施教，關註每位學生的差異，通過個性化的教學輔導後進生，平均提分20分。" }
    ]
};
export const SingaporePageData = {
    list: [
        {
            number: "01",
            color: "#4C9EE1",
            bagColor: "#E9F8FF",
            word: "Phonics of pinyin; Chinese character reading and writing, strokes, radicals; Description of daily communication, simple life scenes.",
        },
        {
            number: "02",
            color: "#4540E1",
            bagColor: "#EFEFFF",
            word: "Improve oral skills, develop vocabulary, phrases, short sentences written and expressed. Complete a short conversation. Complete a short essay of 200 words.",
        },
        {
            number: "03",
            color: "#FC9E5C",
            bagColor: "#FFF4EF",
            word: "Practice dialogue reading, cultivate picture analysis skills, and practice reading pictures and speaking; Cultivate the habit of using high-frequency vocabulary in daily communication and improve reading skills.",
        },
        {
            number: "04",
            color: "#FA8F80",
            bagColor: "#FDF0ED",
            word: "Improve reading skills, be able to use everyday vocabulary and sentences to construct mental logic and discuss a variety of topics. Initially establish a complete article writing system.",
        },
        {
            number: "05",
            color: "#C557CF",
            bagColor: "#FBF0FD",
            word: "Improve paragraph writing skills, be able to learn Chinese characters effectively and complete 400–word essays. Be able to establish logical thinking in Chinese, complete the statement of topics, and choose different styles suitable for writing and speaking.",
        },
        {
            number: "06",
            color: "#71E2A6",
            bagColor: "#F0FCF6 ",
            word: "Expand rich vocabulary, improve language organization skills, and complete 500-word essays. Develop confidence and skills in oral expression through reading discussions, audiovisual material analysis, and in-class interactions to prepare for the PSLE exam.",
        },
    ],
    coursesDataList: [{
        title: " 1-on-1 Online Courses ( No age & time limit)", url: "../assets/singapore/a1.png", info: " Flexible time, tailor made content for you to advance your Chineselanguage abilities, such as: Chinese for Tour, Chinese for Oral Communication, Chinese for Business & HSK Courses."
    }, {
        title: " Small Group Online Courses ( Every Sat. & Sun.)", url: "../assets/singapore/a2.png", info: " S$3 for trial class, and S$8~S$14/class, contact our class arranging teacher now!"
    }],
    reasonList: [
        { wodr: "1-on-1 Private Lessons" },
        { wodr: "Experienced Singapore MOE Syllabus Teachers" },
        { wodr: "Kids Learn Chinese Characters" },
        { wodr: "All age covered courses" },
        { wodr: "Flexible Class Time" },
        { wodr: "Kindly Personalized Helping Teacher" },
    ],
    payTypesList: [
        { time: "Pay by 6 months", price: "$32" },
        { time: "Pay by One Year", price: "$25" },
        { time: "Pay by Month", price: "$40" }
    ],
    parentsList: [
        { "img": "../assets/singapore/banner6.png", content: "My son has learned Chinese with sino-bus since last summer. He has two classes erey week. Although he has to learn Chinese characters and to finish some homework twice a week, he doesn't complain anything about these classes. His teacher is very patient and knowledgeable, so he likes to communicate with her. I feel he has improved a lot. We both recommend sino-bus online Chinese class.", "name": "Jenny Wilson, Italy", "years": "Age 6, Learning Chinese since 2021 Chinese Pre-school Program" },
        { "img": "../assets/singapore/banner3.png", content: "The teacher is good but the service from the company is not good. First, they keep changing the learning advisor. Second, the learning advisor does not have sufficient knowledge on the modules and the child progress. Third, the customer service does not have the authority to book a lesson less than 12 hours in advance even when the cancellation was done due to their mistake!", "name": "freda, Italy", "years": "Age 10, Learning Chinese since 2020 Chinese Pre-school Program" },
        { "img": "../assets/singapore/banner7.png", content: "For me and my kids. the teacher has had a huge impact on why I stay at Sinobus. As I already mentioned to her, she is very efficient, very patient and excellent at customer service. Sinobus should be proud to have employees like her. Without her support, I would have stopped enrolling as the application and course process is very confusing. There needs to be a clearer process about what happens after the trial session.", "name": "elsa, Italy", "years": "Age 8, Learning Chinese since 2022 Chinese Pre-school Program" },
        { "img": "../assets/singapore/banner4.png", content: "My daughter likes to attend the classes and finds the teacher very kind. I am very happy that she likes to attend the classes. The teancher and the team are very responsive whenever I have queries. Will not hesitate to recommend Sino-bus.The courses they run in Singapore are really good!", "name": "sylvia, Italy", "years": "Age 6, Learning Chinese since 2021 Chinese Pre-school Program" },
        { "img": "../assets/singapore/banner2.png", content: "We had a great experience from beginning to now with our coordinator Zoe and our teacher Zhou Laoshi! They make it pleasant and are very welcoming and helpful team! My son has so much fun in the Chinese class in Singapore, he tells me how great it is afterwards! The teacher really has a talent for teaching and enjoys what she does. My child’s Chinese has dramatically improved since starting these clcoursesTitleasses 2x per week.", "name": "purplegrape", "years": "Age 4, Learning Chinese since 2022 Chinese Pre-school Program" },
        { "img": "../assets/singapore/banner5.png", content: "Sino-bus is an amazing program that efficiently helps my children learn! Not only is the content engaging, the teachers are supportive, kind, and hard working. This is the best environment for my children to learn in, I have already seen incredible progress. Sino-bus has numerous resources to help strengthen reading, writing, and speaking skills. I am truly satisfied and would recommend Sino-bus! Children who need to learn Chinese in Singapore can really try.", "name": "frederica,kolt", "years": "Age 6, Learning Chinese since 2021 Chinese Pre-school Program" },
        { "img": "../assets/singapore/banner8.png", content: "My girl has been learning Chinese lesson with Sinobus about half year, the teachers are truly invested in my children’s education and her growth as learners. Adjustments are made as needed to meet the needs of the student. One to one learning program and leveled learning have enabled my children to gain confidence in their learning abilities. Love Ms. Zhou. She is always here when I need help. Thanks for all.", "name": "kelly", "years": "Age 5, Learning Chinese since 2021 Chinese Pre-school Program" },
    ],
    teacherDataList: [{
        "teacherContent": "Graduated from Hebei University of Science and Technology with a bachelor's degree in English, primary school English teacher qualification certificate, English major certificate four; good at HSK, primary school Chinese, easy-to-learn Chinese, four-five fast reading, JIda university Chinese, 2 years of teaching experience in reading and writing;Teaching characteristics: lively and humorous, teach students in accordance with their aptitude, and be patient.",
        "teacherName": "Teacher Wu", "teacherLife": "Teaching English since 2012",
        "data1": "46 active students", "data2": "5,140+ classes"
    }, {
        "teacherContent": "Postgraduate of China University of Petroleum, Putonghua 2A, English level 4, Korean level 6, good at primary school Chinese, pinyin, Chinese teaching, HSK, YCT, happy partner, reading and writing, etc.; Teaching characteristics: patience, cheerful and lively personality, teaching students according to their aptitude, good at guiding;",
        "teacherName": "Teacher Zhu", "teacherLife": "Teaching English since 2012",
        "data1": "52 active students", "data2": "4,990+ classes"
    }, {
        "teacherContent": "Graduated from Hebei Science and Technology Normal University with a bachelor's degree. The existing relevant certificates include: Putonghua 2A, teacher qualification certificate, international Chinese teacher certificate, etc.; 5 years of teaching experience, good at children's Chinese enlightenment, children's reading companionship, primary school reading and writing;Teching characteristics: patience Meticulous, teach students in accordance with their aptitude, entertaining and entertaining.",
        "teacherName": "Teacher Weiwe", "teacherLife": "Teaching English since 2012",
        "data1": "43 active students", "data2": "5,400+ classes"
    }]
};
export const AboutUsPageData = {
    chatTypeList: [{ "typeName": "中国深圳办公中心|全球总部 汉和文化科技(深圳)有限公司", "typeAddess": "中国深圳市南山区沙河街道文昌街社区 香山里花园(二期)2栋1单元2B204", "typePhone": "0755-86083397" },
    { "typeName": "中国深圳办公中心|全球总部 汉和文化科技(深圳)有限公司", "typeAddess": "中国深圳市南山区沙河街道文昌街社区 香山里花园(二期)2栋1单元2B204", "typePhone": "0755-86083397" },
    { "typeName": "中国深圳办公中心|全球总部 汉和文化科技(深圳)有限公司", "typeAddess": "中国深圳市南山区沙河街道文昌街社区 香山里花园(二期)2栋1单元2B204", "typePhone": "0755-86083397" },
    { "typeName": "中国深圳办公中心|全球总部 汉和文化科技(深圳)有限公司", "typeAddess": "中国深圳市南山区沙河街道文昌街社区 香山里花园(二期)2栋1单元2B204", "typePhone": "0755-86083397" },
    { "typeName": "中国深圳办公中心|全球总部 汉和文化科技(深圳)有限公司", "typeAddess": "中国深圳市南山区沙河街道文昌街社区 香山里花园(二期)2栋1单元2B204", "typePhone": "0755-86083397" },
    ]
};
export const substringFun = (fullUrl) => {
    let index = fullUrl.indexOf('gclid');
    let result = '';
    if (index !== -1) {
        result = fullUrl.substring(index + 6); // 从找到的字符后面第三位开始截取
        console.log("-----------", result);
    } else {
        console.log("字符未找到");
    }
    return result;
}